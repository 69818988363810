import React from 'react'
import Helmet from 'react-helmet'

export default ({ twitterImage, ogImage, ogTitle, ogURL, ogDescription }) => {
  return (
    <Helmet>
      <meta name="twitter:image" content={twitterImage} />
      <meta property="twitter:description" content={ogDescription} />
      <meta property="og:image" content={ogImage} />
      <meta property="og:title" content={ogTitle} />
      <meta property="og:url" content={`https://avenue.design${ogURL}`} />
      <meta property="og:description" content={ogDescription} />
      <title>{ogTitle}</title>
    </Helmet>
  )
}
