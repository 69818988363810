import HTMLReactParser from "html-react-parser"
import React from "react"
import ContentCards from "./ContentCards/ContentCards"

// Parse Custom Blocks
export function parseBlockContent(markup) {
  if (!markup) return null

  const options = {
    // eslint-disable-next-line consistent-return
    replace: ({ attribs, children }) => {
      if (!attribs) return undefined

      if (attribs.class && attribs.class.includes("acf-block--content-cards")) {
        const data = JSON.parse(attribs.data)
        const { heading, description, items } = data

        return (
          <div>
            <ContentCards
              heading={heading}
              items={items}
              description={description}
            />
          </div>
        )
      }
      if (attribs.class && attribs.class.includes("wp-block-button__link")) {
        const text = children[0]?.data

        const handleClick = () => {
          if (typeof CustomEvent === "function") {
            let evt = new CustomEvent("controlContactForm")
            document.dispatchEvent(evt)
          }
        }
        if (attribs.href === "#contact") {
          return (
            <button className={attribs.class} onClick={() => handleClick()}>
              {text}
            </button>
          )
        } else {
          return
        }
      }
    },
  }

  const Content = HTMLReactParser(markup, options)

  return Content
}
