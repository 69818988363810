import React from 'react'
import { Link } from 'gatsby'

import Img from '../responsiveCloudinary'

import styles from './relatedCaseStudy.module.scss'
import AvenueImg from '../../components/AvenueImg'
import styled from 'styled-components'

class Template extends React.Component {
  render() {
    const { title, image, link } = this.props

    return (
      <section className={`${styles.block}`}>
        <Link to={link}>
          <div className={`row ${styles.relative}`}>
            <div className={`columns ${styles.relative}`}>
              <div className={styles.title}>{title}</div>
            </div>
          </div>
          <ImageBlock>
            <AvenueImg fluid={image} />
          </ImageBlock>
        </Link>
      </section>
    )
  }
}

export default Template

const ImageBlock = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  .avenueImg {
    min-width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: 0;
    top: 0;
    transition: transform 500ms ease-in-out;

    img {
      transition: transform 500ms ease-in-out !important;
    }
  }
`
